<template>
  <div class=" mx-auto">
            <div>
                <Loading :isLoading= "this.isLoading" />
                <div style="display: flex;margin:0 auto; margin-bottom:20px;">
                    <div class="div_centrado" style="margin:0 auto;background:white;width:95%;height:auto;margin-top:50px;border-radius:14px; text-align: center;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
                        <div class="flex" style="margin-top:40px;">
                            <div class="w-full">
                                <p class="title">
                                    Autorización de Descuento
                                </p>
                            </div>
                        </div>
                        <div class="flex" style="margin-top:0px;">
                            <div class="w-full">
                                <h4 class="steps">Paso 8 de 10</h4>
                            </div>
                        </div>
                        <div class="flex" style="margin-top:20px;">
                            <div class="w-full">
                                <p style="font-family:Roboto;color:#8e8e8e;padding-left: 180px;padding-right: 180px;text-align: center;">
                                    <h4 class="subTitle" style="text-align:center;">Autorización de descuento</h4>
                                </p>
                            </div>
                        </div>

                        <div class="flex" style="margin-top:10px;">
                            <div class="w-full">
                                <p class="label-description" v-if="discount_is_by_payroll == true" style="font-size:15px;font-family:Roboto;color:#8e8e8e;text-align:center;padding-left: 10px;padding-right: 10px;">
                                    Autorizo que FONCABSA instale un descuento en mi nómina por la cantidad de: <span style="font-weight:700;"> {{ $filters.formatCurrency(discount) }} </span> de forma {{payment_frecuency}}
                                </p>
                                <p class="label-description" v-if="discount_is_by_payroll == false" style="font-size:15px;font-family:Roboto;color:#8e8e8e;text-align:center;padding-left: 10px;padding-right: 10px;">
                                    Autorizo que FONCABSA instale un descuento por domiciliación por la cantidad de: <span style="font-weight:700;"> {{ $filters.formatCurrency(discount) }} </span>  de forma mensual
                                </p>

                            </div>
                        </div>

                        <div class="flex" style="margin-top:20px;">
                            <div class="w-full">
                                <input class="custom_button" id="Button_AcceptAutorize" type="button" style="border: 2px solid;border-color: #E4E7EB;width:160px"
                                v-bind:class="[
                                'hover:bg-gray-200 text-xl mr-2 py-1 px-13 m-2 rounded-full',
                                acceptConditions ? 'bg-gray-300' : 'bg-white',
                                ]"
                                v-on:click="
                                    (acceptConditions = true)
                                "
                                value="Si"
                            />
                            <input class="custom_button" id="Button_NotAcceptAutorize" type="button" style="border: 2px solid;border-color: #E4E7EB;width:160px"
                                v-bind:class="[
                                'hover:bg-darkGray text-xl ml-2 py-1 px-13 m-2 rounded-full',
                                acceptConditions === false ? 'bg-gray-300' : 'bg-white',
                                ]"
                                v-on:click="
                                    (acceptConditions = false), alertNotAcceptAutorize()
                                "
                                value="No"
                            />
                            </div>
                        </div>

                        <!--<div class="flex; flex-wrap:wrap" v-show="acceptConditions">
                          <div style="margin-top:30px;">
                            <div class="w-full">
                                  <p style="font-size:15px;font-family:Roboto;color:#8e8e8e;text-align:center;padding-left: 10px;padding-right: 10px;">
                                      En base al acuerdo de realizar una aportación de Parte Social por $400.00, ¿Cuál sería su forma de pago?
                                  </p>
                              </div>
                          </div>

                          <div class="flex" style="margin-top:30px;justify-content:center;">
                              <div style="width:50%;">
                                  <div class="form-group">
                                    <label class="label-input-radio">Elige la forma de pago del Parte Social:</label>
                                    <select name="select" id="Select_PaymentType" class="form-control inputs-form" v-model="paymentType" required>
                                      <option value="banco">Pago al banco</option>
                                      <option value="transferencia">Transferencia</option>
                                      <option value="tarjeta">Pago con tarjeta</option>
                                    </select>
                                  </div>
                              </div>
                          </div>

                          <div class="flex" style="margin-top:10px;justify-content:center;">
                          <p style="font-size:15px;font-family:Roboto;color:#8e8e8e;text-align:center;padding-left: 10px;padding-right: 10px;">
                                Banco:                 ACTINVER SA<br>

                                CLABE:                 133180000127863072<br>

                                Nombre:             FONCABSA S.C DE A.P. DE R.L DE C.V.
                              </p>
                          </div>-->

                          <!--<div style="margin-top:20px;padding-right: 10px;">
                              <div class="w-full">
                                  <p style="">
                                      <a href="/cuenta/previsualizacion-autorizo-descuento" target="_blank">
                                          <input type="button" value="Ver Autorizo de Descuento" class="bg-gray-300" style="text-decoration:none;
                                          font-family:Roboto;
                                          font-size: 16px;
                                          color:#000;
                                          padding-top:5px;
                                          padding-bottom:5px;
                                          padding-left:55px;
                                          padding-right:55px;
                                          border: 0px;
                                          border-width: 3px;border-radius:35px;"/>
                                      </a>
                                  </p>
                              </div>
                          </div>
                        </div> -->
                        <br>
                        <div style="display:flex;justify-content:center">
                          <div>
                            <p>
                              <input type="button" class="next_button" value="Siguiente" :disabled="!acceptConditions"
                              v-bind:style="{opacity: acceptConditions ? activeColor : activeColor2}"
                              v-on:click="Next()"/>
                            </p>
                          </div>
                        </div>
                        <div style="display:flex;justify-content:center; margin-top:5px">
                          <div>
                            <p>
                              <a href="/cuenta/carga-documentos/0" style="text-decoration:none;">
                                <input type="button" class="back_button" value="Atrás"/>
                              </a>
                            </p>
                          </div>
                        </div>
                        <br>

                    <br>


                        <!-- <div class="flex-1 bg-gray-0 px-0 py-0 m-0 md:mt-20" style="">
                            <div class="row">
                                <div class="col col-sm-6">
                                    <p>
                                        <a href="cuenta/beneficiarios" style="text-decoration:none;">
                                            <input type="button" class="regresar" value="Regresar"
                                                style="text-decoration:none;
                                                font-family:Roboto;
                                                font-size: 16px;
                                                color:#FFFFFF;
                                                padding-top:5px;
                                                padding-bottom:5px;
                                                padding-left:55px;
                                                padding-right:55px;
                                                background-color:#FEB72B;
                                                border: 0px solid;
                                                border-width: 3px;border-radius:35px;
                                                float:right;" />
                                        </a>
                                    </p>
                                </div>
                                <div class="col col-sm-6">
                                    <p>
                                        <input type="button" class="register" value="Terminar registro" :disabled="!acceptConditions"
                                        v-bind:style="{opacity: acceptConditions ? activeColor : activeColor2}"
                                        style="text-decoration:none;
                                        font-family:Roboto;
                                        font-size: 16px;
                                        color:#FFFFFF;
                                        padding-top:5px;
                                        padding-bottom:5px;
                                        padding-left:55px;
                                        padding-right:55px;
                                        background-color:#FEB72B;
                                        border: 0px solid;
                                        border-width: 3px;border-radius:35px;
                                        float:left;"
                                        v-on:click="generateDocumentSaving()"/>
                                    </p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
<!-- <button v-on:click="changeIcoPassword()"><img id="imageHidePasswordConfirm" src="../assets/visibility-off.png" width="20" height="20"></button> -->


    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!-- <link href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css" rel="stylesheet"> -->
    <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
  </div>
</template>


<script>
import('@/assets/css/stylesheet.css')
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Menu from "@/components/Menu.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";
import {saveRequest} from "@/api/user";
import moment from "moment";
import {  getTokenDecoden } from "@/helpers/tokenauth";

export default {
  data() {
    return {
      activeColor: "1",
      activeColor2: "0.4",
      acceptConditions: null,
      rfc: "",
      paternal: "",
      maternal: "",
      fisrt_name: "",
      last_name: "",
      cellphone: "",
      email: "",
      state: "",
      city: "",
      password: "",
      typeReference: "",
      account_type: "",
      multiApi: process.env.VUE_APP_MULTIAPI,
      ccapi: process.env.VUE_APP_CCAPI,
      isLoading: false,
      member_id: "",
      listBeneficiaries: [],
      emailUser: "",
      birthday: "",
      json: null,
      user_rol: null,
      isFinishRegister:false,
      url:null,
      fullname: "",
      ahorroMonto: "",
      admission_id: "",
      catalog_name:"",
      discount: "",
      contribution: "",
      admission_status: "",
      paymentType: "",
      discount_is_by_payroll:false,
      payment_frecuency:""
    };
  },
  name: "AvisoPrivacidadAutorizoDescuento",
  components: {
    MenuLateral,
    Menu,
    Loading,
  },
  mounted() {
  },
  watch: {
  },
  async beforeMount() {
    await this.loadInfo();
    await this.discountIsByPayroll();
  },
  methods: {
    async discountIsByPayroll(){
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      var discount_type = json.data.response.admission_request.admission_information.saving_registration_attributes.payment_method;
      this.payment_frecuency = json.data.response.admission_request.admission_information.saving_registration_attributes.payment_frecuency;
      this.discount_is_by_payroll = true
      if(discount_type == "DOMICILIACIÓN"){
        this.discount_is_by_payroll = false
      }
    },
    async alertNotAcceptAutorize(){
      await Swal.fire({
        title: "Aviso",
        text: "Es necesario aceptar el autorizo de descuento para continuar con la solicitud de ingreso",
        icon: "warning",
        confirmButtonColor: '#FEB72B',
      });
    },
    setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
      sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
    },
    getJsonFromLocalStorageBy(user_id) {
      return JSON.parse(window.sessionStorage.getItem(user_id));
    },
    async Next(){
      if (this.admission_status == "carga_documento_completa") {
        this.isLoading=true;
        var infoJson = this.buildJson();
        await saveRequest(infoJson).then((response) => {
          this.isLoading=false;
          this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
          this.$router.push({path:"/cuenta/video/admission_request"});
        })
        .catch((error) => {
          this.isLoading=false;
        }).finally( () => {
        })
      } else {
        // window.location.href = "/cuenta/video/admission_request";
        this.$router.push({path:"/cuenta/video/admission_request"});
      }
    },
    buildJson() {
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      
      return {
        token_auth: sessionStorage.getItem("login"),
        admission_request_id: this.admission_id,
        user_id: this.user_id,
        change_for: "accept_autorize_discount",
        admission_request_params: {
          admission_information: json.data.response.admission_request.admission_information,
        },
      };
    },
    async showMessage(){
      this.isLoading=false;
      if(this.isFinishRegister){
        await Swal.fire({
          title: "Aviso",
          text: `Hemos enviado el autorizo de descuento para que lo firme al correo proporcionado.
            Si no lo encuentra, favor de buscar en correo no deseado o en spam.
            Una vez que lo haya firmado se iniciará el proceso de revisión de solicitud de ingreso,
            y en breve se le hará llegar su solicitud de ingreso en caso de haber sido aceptado para que la firme`,
          icon: "success",
          confirmButtonColor: '#FEB72B',
        });
      }else{
        await Swal.fire({
          title: "Aviso",
          text: "Se presentó un error. No fue posible terminar el registro.",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
      }
      // window.location.href ="/cuenta/principal";
      this.$router.push({path:"/cuenta/principal"});
    },
    changePercentage(value) {
      if (value.includes(".")) {
        value = parseFloat(value);
      }

      var value = parseInt(value);
      if (value < 1 || value > 100) {
        value = 0;
      }
      return value;
    },
    establisDateMaxToday() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      today = yyyy + "-" + mm + "-" + dd;
      document.getElementById("birthDate").setAttribute("max", today);
    },
    formatMoney(value) {
      const options2 = { style: "currency", currency: "MXN" };
      const formatter = new Intl.NumberFormat("en-US", options2);
      var resultado = formatter.format(value);
      resultado = resultado.replace("MX", "");
      return resultado;
    },
    formatDate(date) {
      var d = new Date(date);
      var month = "" + (d.getMonth() + 1);
      var day = "" + d.getDate();
      var year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    addUpperCase(value) {
      if (value != "") {
        value = value.toUpperCase();
      }
      return value;
    },
    addLowerCase(value) {
      if (value != "") {
        value = value.toLowerCase();
      }
      return value;
    },
    orderDateByDayMonthYear(date, typeSplit) {
      var elemtsOfDate = date.split(typeSplit);
      var year = elemtsOfDate[0];
      var month = elemtsOfDate[1];
      var day = elemtsOfDate[2];
      return day + "/" + month + "/" + year;
    },
    calculateNumberEntryTwoDates(date) {
      var dateToday = new Date();
      var dateAdminision = new Date(date);

      var antiguedadd = dateToday.getFullYear() - dateAdminision.getFullYear();

      dateAdminision.setFullYear(dateAdminision.getFullYear() + antiguedadd);

      if (dateAdminision > dateToday) {
        antiguedadd--;
      }
      return antiguedadd;
    },
    async encriptarMensaje(mensaje) {
      const header = { alg: "HS256", typ: "JWT" };
      const encodedHeader = Buffer.from(JSON.stringify(header)).toString(
        "base64"
      );

      var data = {
        html: mensaje,
      };
      const encodedPayload = Buffer.from(JSON.stringify(data)).toString(
        "base64"
      );
      var jwtSecret = "vM22%!SmnjNV";
      const crypto = require("crypto");
      const signature = crypto
        .createHmac("sha256", jwtSecret)
        .update(encodedHeader + "." + encodedPayload)
        .digest("base64");
      var tokenMessage = encodedHeader + "." + encodedPayload + "." + signature;
      return tokenMessage;
    },
    async loadInfo() {
      var auth = getTokenDecoden();
      this.emailUser = auth.obj.person["email"];
      this.user_id = auth["id"];
      this.user_rol =  auth.obj["role"];

      if(this.user_rol == "member"){
        this.catalog_name = "documentos_alta_socios"
      }
      else{
        this.catalog_name = "discount_authorize_cabsa"
      }
      this.searchInfo();
    },
    searchInfo() {
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      this.admission_status = json.data.response.admission_request.status;
      this.rfc = json.data.response.admission_request.admission_information.rfc;
      this.admission_id = json.data.response.admission_request.id;
      this.discount = json.data.response.admission_request.admission_information.saving_registration_attributes.amount;
      this.contribution = json.data.response.admission_request.admission_information.salary_info_attributes.liquidity;
      console.log(this.admission_status)
    },
    currency(value) {
      const options2 = { style: 'currency', currency: 'MXN' };
      //const formatter = new Intl.NumberFormat('es-MX', options2);
      const formatter = new Intl.NumberFormat('en-US', options2);

      if (value != "" && value != undefined) {
        var resultado = Number(value.toString().replace(/[^0-9.-]+/g,"")).toString();

        if(!value.toString().includes(".")){
            resultado = value.toString() + ".00"
        }

        if(!resultado.includes(",")){
            resultado = resultado.replace(",","")//formatter.format(value)
        }
        resultado = formatter.format(resultado)

        resultado = resultado.replace('MX$','')

        if (resultado =="NaN" || resultado < 0) {
            resultado = ""
        }
        return '$' + resultado;
      }
      return '$0.00';
    },
  },
};
</script>

<style>
.custom_buttom {
  border-radius: 5px !important;
  border: 1px solid #FFCC00;
  padding-top: 0%;
  color: #FFCC00;
  font-family: Roboto !important;
  font-size: 16px !important;
  width: 300px;
  height: 40px;
}
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}
@media (max-width: 500px) {
  #button_popups {
    height: 50px !important;
  }
}

button {
  outline: none !important;
}

.inputText {
  border: 1px solid #BFD243 !important;
  height: 30px !important;
  text-transform: uppercase;
  border-radius:5px;
}

</style>
